#root {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;

  background-color: rgb(50, 48, 135);
}

main {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(50, 48, 135);
  width: 100%;
  min-height: 700px;
  max-height: 100%;
}

section {
  background-color: white;

  min-height: 100%;
  width: 80%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main_body {
  display: flex;
  flex-direction: row;
}

.main_content {
  display: flex;
  border-radius: 20px;
  border: 1px solid rgb(228, 228, 228);
  background-color: #fdfdfd;
  width: 600px;
  height: 200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headerText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.headerLogo {
  width: 80px;
  height: 80px;
}

.oiTextL {
  font-size: 24px;
}

.oiTextXL {
  font-size: 26px;
  font-weight: 500;
}

header {
  height: 100px;
  margin-bottom: 1%;
  padding-right: 4%;
    width: 80%;
    text-align: left;
    display: flex;
    flex-direction: row;
    font-size: 80%;
    align-items: center;
    justify-content: center;
}

.stackSection {
  width: 80%;
}

.mainButton {
  width: 200px;
  height: 60px;
  border: 2px solid rgb(211, 211, 211);
  border-radius: 10px;
  color: black;
  background-color: rgb(247, 247, 247);
  font-weight: 500;
  font-size: 16px;
  cursor:pointer;
}

.mainButton:hover {
  background-color: rgb(235, 235, 235);
  transition: .3s;
  color: black;
}

.footerNote {
  background-color: #fdfdfd;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  font-size: 18px;
  height: 50px;
  padding: 20px 16px 6px;
  position: static;
  text-align: center;
  margin-top: 20px;
  width: 100%;
}